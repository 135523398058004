<template>
<div class="Part">
  <el-row class="input"><!--功能按钮-->

    <el-col :span="6">
      <el-row>
        <el-button type="primary" @click="addSchool" size="mini" v-if="authe.Hotel_Personnel_UserInfo_AddStorefront">新增店面</el-button>
        <el-button type="primary" @click="modifySchool" size="mini" v-if="authe.Hotel_Personnel_UserInfo_ModifyStorefront">修改店面</el-button>
        <el-dialog title="查看店面" v-if="storeState" v-loading="loading" :visible.sync="dialogSchool" @close="closeSchoolDialog" :close-on-click-modal="false" width="500px">
          <div class="project-detail">
            <div class="describe">
              <el-row>
                <el-col :span="12">
                  <span>店面名称：</span>
                  {{formLeft.storefrontName}}
                </el-col>
                <el-col :span="12">
                  <span>店面状态：</span>
                  {{formLeft.storefrontState}}
                </el-col>
                
              </el-row>
            </div>
            <div class="describe">
              <el-row>
                <el-col :span="12">
                  <span>店面地址：</span>
                  {{formLeft.storefrontAddress}}
                </el-col>
              </el-row>
            </div>
            <div class="describe">
              <el-row>
                <el-col :span="24">
                  <span>店面描述：</span>
                  {{formLeft.storefrontNote}}
                </el-col>
              </el-row>
            </div>
          </div>
          <div slot="footer" v-show="!storeState">
            <el-button @click="dialogSchool = false">取 消</el-button>
            <el-button type="primary" v-if="showState" @click="modifySchoolSave">保 存</el-button>
            <el-button type="primary" v-else @click="addSchoolSave">保 存</el-button>
          </div>
        </el-dialog>
        <el-dialog :title="this.title" v-show="!storeState1" v-loading="loading" :visible.sync="dialogSchool1" @close="closeSchoolDialog" :close-on-click-modal="false" width="400px">
          <el-form :model="formLeft" ref="schoolForm" :rules="rules">
            <el-form-item label="店面名称" :label-width="labelWidth" prop="storefrontName">
              <el-input v-model="formLeft.storefrontName" @change="demo" size="mini" style="width:80%"></el-input>
            </el-form-item>
            <el-form-item label="店面地址" :label-width="labelWidth">
              <el-input v-model="formLeft.storefrontAddress" size="mini" style="width:80%"></el-input>
            </el-form-item>
            <el-form-item v-show="showState" label="店面状态" :label-width="labelWidth">
              <el-select v-model="formLeft.storefrontState" size="mini" style="width:80%">
                <el-option label="正常" value="正常"></el-option>
                <el-option label="注销" value="注销"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="店面描述" :label-width="labelWidth">
              <el-input v-model="formLeft.storefrontNote" size="mini" style="width:80%;margin-top:5px" type="textarea"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="dialogSchool1 = false">取 消</el-button>
            <el-button type="primary" v-if="showState" @click="modifySchoolSave">保 存</el-button>
            <el-button type="primary" v-else @click="addSchoolSave">添 加</el-button>
          </div>
        </el-dialog>

      </el-row>
      <el-row style="padding: 5px 5px 0 0;">
        店面状态：<el-select v-model="formLeft.schoolState" @change="getDataLeft" size="mini" clearable style="width:100px">
          <el-option label="正常" value="正常"></el-option>
          <el-option label="注销" value="注销"></el-option>
        </el-select>
      </el-row>
    </el-col>

    <el-col :span="6">
      <el-row>
        <el-button type="primary" @click="addDepartment" size="mini" v-if="authe.Hotel_Personnel_UserInfo_AddDepartment">新增部门</el-button>
        <el-button type="primary" @click="modifyDepartment" size="mini" v-if="authe.Hotel_Personnel_UserInfo_ModifyDepartment">修改部门</el-button>
          
          <el-dialog :title="this.title" v-loading="loading" :visible.sync="dialogDepartment" @close="closeDepartmentDialog" :close-on-click-modal="false" width="400px">
          <el-form :model="formCenter" ref="departmentForm" :rules="rules">
            <el-form-item label="店面位置" :label-width="labelWidth">
              <el-select v-model="formCenter.storefrontId" disabled size="mini" style="width:80%">
                <el-option v-for="(value,i) in this.dataListLeft" :key="i" :label="value.storefrontName" :value="value.storefrontId.toString()"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="部门名称" :label-width="labelWidth" prop="departmentName">
              <el-input v-model="formCenter.departmentName" size="mini" style="width:80%"></el-input>
            </el-form-item>
            <el-form-item v-show="showState" label="部门状态" :label-width="labelWidth">
              <el-select v-model="formCenter.departmentState" size="mini" style="width:80%">
                <el-option label="正常" value="正常"></el-option>
                <el-option label="注销" value="注销"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="部门描述" :label-width="labelWidth">
              <el-input type="textarea" v-model="formCenter.departmentNote" size="mini" style="width:80%;margin-top:5px"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="dialogDepartment = false">取 消</el-button>
            <el-button type="primary" v-if="showState" @click="modifyDepartmentSave">保 存</el-button>
            <el-button type="primary" v-else @click="addDepartmentSave">保 存</el-button>
          </div>
        </el-dialog>
      </el-row>
      <el-row style="padding: 5px 5px 0 0;">
        部门状态：<el-select v-model="formCenter.depState" @change="getDataCenter" clearable size="mini" style="width:100px">
          <el-option label="正常" value="正常"></el-option>
          <el-option label="注销" value="注销"></el-option>
        </el-select>
      </el-row>
    </el-col>

    <el-col :span="12">
      <el-row>
        <el-button type="primary" @click="addTeacher" size="mini" v-if="authe.Hotel_Personnel_UserInfo_NewUsers">新增员工</el-button>
          <el-dialog title="新增员工" v-loading="loading" :visible.sync="dialogAddTeacher" @close="cleanDialogTeacher" :close-on-click-modal="false" width="900px">
            <el-row>
              <el-col :span="18">
                <el-form :model="teacher" ref="teacherForm" :rules="rules">
                  <el-row>
                    <el-col :span="10">
                      <el-form-item label="姓名" :label-width="labelWidth" prop="suStaffName">
                        <el-input v-model="teacher.suStaffName" size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="电话" :label-width="labelWidth" prop="suContact">
                        <el-input v-model="teacher.suContact" @input="suContactInput" size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="民族" :label-width="labelWidth">
                        <el-input v-model="teacher.suStaffNation" size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="运维app" :label-width="labelWidth">
                        <el-select v-model="teacher.suAppAuth" size="mini">
                          <el-option label="是" value="1"></el-option>
                          <el-option label="否" value="0"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="员工状态" :label-width="labelWidth">
                        <el-select v-model="teacher.suState" size="mini">
                          <el-option label="正常" value="正常"></el-option>
                          <el-option label="注销" value="注销"></el-option>
                          <el-option label="离职" value="离职"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="婚姻状况" :label-width="labelWidth">
                        <el-select v-model="teacher.suStaffMarriage" size="mini">
                          <el-option label="未婚" value="未婚"></el-option>
                          <el-option label="已婚" value="已婚"></el-option>
                          <el-option label="离异" value="离异"></el-option>
                          <el-option label="其他" value="其他"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="联系人关系" :label-width="labelWidth">
                        <el-select v-model="teacher.suStaffLinkmanrelation" size="mini">
                          <el-option label="父母" value="父母"></el-option>
                          <el-option label="兄弟" value="兄弟"></el-option>
                          <el-option label="姐妹" value="姐妹"></el-option>
                          <el-option label="子女" value="子女"></el-option>
                          <el-option label="亲属" value="亲属"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="备注信息" :label-width="labelWidth">
                        <el-input type="textarea" rows="3" v-model="teacher.suStaffServicememotextnull" class="textarea" style="margin-top:5px"></el-input>
                      </el-form-item>
                      <el-form-item label="授权密码" :label-width="labelWidth">
                        <el-input v-model="suDiscountAuthPassword.authPassword" show-password size="mini"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="14">
                      <el-form-item label="籍贯" :label-width="labelWidth">
                        <el-input v-model="teacher.suStaffNativeplace" size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="证件号码" :label-width="labelWidth">
                        <el-input v-model="teacher.suIdcard" size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="证件地址" :label-width="labelWidth">
                        <el-input v-model="teacher.suStaffAddress" size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="居住地址" :label-width="labelWidth">
                        <el-input v-model="teacher.suStaffCurrentaddress" size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="员工账号" :label-width="labelWidth">
                        <el-input v-model="teacher.suName" readonly size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="登录密码" :label-width="labelWidth" prop="suPassword">
                        <el-input v-model="teacher.suPassword" show-password size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="紧急联系人" :label-width="labelWidth">
                        <el-input v-model="teacher.suStaffLinkman" size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="联系人电话" :label-width="labelWidth">
                        <el-input v-model="teacher.suStaffLinkmanphone" size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="备注" :label-width="labelWidth">
                        <el-input v-model="teacher.suStaffRemark" size="mini"></el-input>
                      </el-form-item>
                      <el-form-item label="授权卡密码" :label-width="labelWidth">
                        <el-input v-model="suDiscountAuthPassword.cardPassword" show-password size="mini"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-col>
              <el-col :span="6" style="text-align:center;">
                <el-image style="width:160px;height:200px;margin:7px" :src="teacher.url">
                   <div slot="error" class="image-slot">
                      <img src="../../assets/images/userImage.png">
                    </div>
                </el-image>
                <el-button @click="startFun" size="mini">读取身份证</el-button>
              </el-col>
            </el-row>

            <div slot="footer">
              <el-alert
                v-if="psdWarningAlert"
                style="text-align: left;float:left;width: 80%;margin: -5px 0 -10px"
                type="error"
                :description="psdWarningMessage"
                show-icon>
              </el-alert>
              <el-button @click="dialogAddTeacher = false">取 消</el-button>
              <el-button type="primary" @click="addTeacherSave">保 存</el-button>
            </div>
          </el-dialog>

          <el-dialog title="修改员工" v-loading="loading" :visible.sync="dialogModifyTeacher" @close="cleanDialogTeacher" :close-on-click-modal="false" width="900px">
            
            <el-tabs v-model="tab" @tab-click="tabChange">
              <el-tab-pane label="修改员工信息" name="first" v-if="authe.Hotel_Personnel_UserInfo_ModifyInformation">
                <el-row>
                  <el-col :span="18">
                    <el-form :model="teacher" ref="teacherForm" :rules="rules">
                      <el-row>
                        <el-col :span="10">
                          <el-form-item label="姓名" :label-width="labelWidth" prop="suStaffName">
                            <el-input v-model="teacher.suStaffName" size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="电话" :label-width="labelWidth" prop="suContact">
                            <el-input v-model="teacher.suContact" @input="suContactInput" size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="民族" :label-width="labelWidth">
                            <el-input v-model="teacher.suStaffNation" size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="运维app" :label-width="labelWidth">
                            <el-select v-model="teacher.suAppAuth" size="mini">
                              <el-option label="是" :value="Number('1')"></el-option>
                              <el-option label="否" :value="Number('0')"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="员工状态" :label-width="labelWidth">
                            <el-select v-model="teacher.suState" size="mini">
                              <el-option label="正常" value="正常"></el-option>
                              <el-option label="注销" value="注销"></el-option>
                              <el-option label="离职" value="离职"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="婚姻状况" :label-width="labelWidth">
                            <el-select v-model="teacher.suStaffMarriage" size="mini">
                              <el-option label="未婚" value="未婚"></el-option>
                              <el-option label="已婚" value="已婚"></el-option>
                              <el-option label="离异" value="离异"></el-option>
                              <el-option label="其他" value="其他"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="联系人关系" :label-width="labelWidth">
                            <el-select v-model="teacher.suStaffLinkmanrelation" size="mini">
                              <el-option label="父母" value="父母"></el-option>
                              <el-option label="兄弟" value="兄弟"></el-option>
                              <el-option label="姐妹" value="姐妹"></el-option>
                              <el-option label="子女" value="子女"></el-option>
                              <el-option label="亲属" value="亲属"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="备注信息" :label-width="labelWidth">
                            <el-input type="textarea" rows="3" v-model="teacher.suStaffServicememotextnull" class="textarea" style="margin-top:5px"></el-input>
                          </el-form-item>
                          <el-form-item label="授权密码" :label-width="labelWidth">
                            <el-input v-model="teacher.suDiscountAuthPassword.authPassword" show-password size="mini"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="14">
                          <el-form-item label="籍贯" :label-width="labelWidth">
                            <el-input v-model="teacher.suStaffNativeplace" size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="证件号码" :label-width="labelWidth">
                            <el-input v-model="teacher.suIdcard" size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="证件地址" :label-width="labelWidth">
                            <el-input v-model="teacher.suStaffAddress" size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="居住地址" :label-width="labelWidth">
                            <el-input v-model="teacher.suStaffCurrentaddress" size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="员工账号" :label-width="labelWidth">
                            <el-input v-model="teacher.suName" readonly size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="登录密码" :label-width="labelWidth" prop="suPassword">
                            <el-input v-model="teacher.suPassword" show-password size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="紧急联系人" :label-width="labelWidth">
                            <el-input v-model="teacher.suStaffLinkman" size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="联系人电话" :label-width="labelWidth">
                            <el-input v-model="teacher.suStaffLinkmanphone" size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="备注" :label-width="labelWidth">
                            <el-input v-model="teacher.suStaffRemark" size="mini"></el-input>
                          </el-form-item>
                          <el-form-item label="授权卡密码" :label-width="labelWidth">
                            <el-input v-model="teacher.suDiscountAuthPassword.cardPassword" show-password size="mini"></el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-col>
                  <el-col :span="6" style="text-align:center;">
                    <el-image style="width:160px;height:200px;margin:7px" :src="teacher.url">
                      <div slot="error" class="image-slot">
                        <img src="../../assets/images/userImage.png">
                      </div>
                    </el-image>
                    <el-button @click="startFun" size="mini" v-if="authe.Hotel_Personnel_UserInfo_ReadIdentityCard">读取身份证</el-button>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane label="用户跟进" name="second" v-if="authe.Hotel_Personnel_UserInfo_UserFollowUp">
                <el-table 
                :data="dataFollowUp" 
                highlight-current-row 
                max-height="400px" 
                size="mini" 
                style="width:100%;">
                  <el-table-column prop="time" label="跟进时间" width="140px"></el-table-column>
                  <el-table-column prop="registrantName" label="跟进人" min-width="20px"></el-table-column>
                  <el-table-column prop="type" label="归属" min-width="30px"></el-table-column>
                  <el-table-column prop="text" label="跟进内容" min-width="200px"></el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="任职信息" name="third" v-if="authe.Hotel_Personnel_UserInfo_JobInformation">
                <el-form style="padding: 0 0 0 60px">
                  <el-form-item>
                    <p>职位信息</p>
                    店面：<el-input v-model="serving.storefrontName" disabled size="mini" style="width:25%"></el-input>&nbsp;&nbsp;
                    部门：<el-input v-model="serving.departmentName" disabled size="mini" style="width:25%"></el-input>&nbsp;&nbsp;
                    岗位：<el-select v-model="serving.suType" size="mini" style="width:25%">
                        <el-option v-for="(value,i) in this.dataPost" :key="i" :label="value.variable" :value="value.variable"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <p>基本信息</p>
                    <el-form-item>
                      权限等级：<el-select v-model="serving.suPermissionsId" size="mini" style="width:28%">
                        <el-option v-for="(value,i) in this.dataJurisdiction" :key="i" :label="value.spName" :value="value.spId"></el-option>
                      </el-select>&nbsp;&nbsp;
                      直属上级：<el-cascader
                        :options="dataName"
                        v-model="serving.suSuperior"
                        filterable
                        clearable
                        :props="{ expandTrigger: 'hover' }"
                        size="mini"
                        style="width:29%"
                      ></el-cascader>&nbsp;&nbsp;
                    </el-form-item>
                  </el-form-item>
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-form-item label="主权限角色" size="mini">
                        <el-select v-model="serving.mainRole">
                          <el-option
                           v-for="item in roleOpt"
                          :key="item.srId" :label="item.srRoleName" 
                          :value="item.srId
                          "></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="兼职权限角色" size="mini">
                        <el-select v-model="serving.otherRole" multiple>
                          <el-option
                           v-for="item in roleOpt"
                           v-show="item.srId !== serving.mainRole"
                          :key="item.srId"
                          :label="item.srRoleName" 
                          :value="item.srId
                          "></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <div slot="footer">
                  <el-button @click="renzhixinxi = false">取 消</el-button>
                  <el-button type="primary" @click="renzhixinxi = false">保 存</el-button>
                </div>
              </el-tab-pane>
              <el-tab-pane label="管理设备" name="fourth" v-if="authe.Hotel_Personnel_UserInfo_ManagementEquipment">
                <div class="transferStyle" style="display: flex; align-items: center; justify-content: center;">
                  <el-transfer 
                    :data="dataBinding" 
                    v-model="dataUnbinding" 
                    :props="{key: 'id',label: 'name'}" 
                    :titles="['未绑定','已绑定']"
                    filterable
                    filter-placeholder="搜索设备安装地址或设备名称"
                  ></el-transfer>
                </div>
              </el-tab-pane>
              <el-tab-pane label="人脸授权" name="fifth" v-if="authe.Hotel_Personnel_UserInfo_FaceAuthorization">
                设备名称：<el-input v-model="face.devNickname" @change="searchAuthorized" size="mini" style="width:20%"></el-input>&nbsp;
                设备SN：<el-input v-model="face.devSn" @change="searchAuthorized" size="mini" style="width:20%"></el-input>&nbsp;&nbsp;
                设备安装地址: <el-input v-model="face.deviceAddress" @change="searchAuthorized" size="mini" style="width:20%"></el-input>
                <el-button type="primary" @click="addFace" size="mini" v-if="authe.Hotel_Personnel_UserInfo_AddAuthorization">添加授权</el-button>
                <el-table 
                :data="dataFaceAuthorized" 
                style="width:100%;margin-bottom:10px">
                  <el-table-column prop="idftName" label="设备类型" width="100px"></el-table-column>
                  <el-table-column prop="idstName" label="设备型号" width="150px"></el-table-column>
                  <el-table-column prop="devNickname" label="设备名称" width="130"></el-table-column>
                  <el-table-column prop="hsAddCommunity" label="设备安装地址" width="150" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="ifpdStatus" label="状态" width="75"></el-table-column>
                  <el-table-column prop="devSn" label="设备SN" width="125px"></el-table-column>
                  <el-table-column min-width="60px">
                    <template slot-scope="scope">
                      <el-button @click="deleteAuthorized(scope.row)" type="danger" plain size="mini">删除授权</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="客服管理" name="eight">
                  <el-form :model="service" style="width: 300px;margin: 0 auto">
                    <el-form-item label="客服账号" label-width="100px">
                      <el-input v-model="service.jwucKfAccount" size="small" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="客服昵称" label-width="100px">
                      <el-input v-model="service.jwucKfNick" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="客服密码" label-width="100px">
                      <el-input show-password v-model="service.jwucKfPassword" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="用户微信号" label-width="100px">
                      <el-input v-model="service.jwucKfWx" size="small"></el-input>
                    </el-form-item>
                  </el-form>
              </el-tab-pane>
            </el-tabs>

            <div slot="footer">
              <div v-if="tab == 'first'"><!--修改员工信息-->
                <el-alert
                  v-if="psdWarningAlert"
                  style="text-align: left;float:left;width: 80%;margin: -5px 0 -10px"
                  type="error"
                  :description="psdWarningMessage"
                  show-icon>
                </el-alert>
                <el-button @click="dialogModifyTeacher = false">取 消</el-button>
                <el-button type="primary" @click="modifyTeacherSave" v-if="authe.Hotel_Personnel_UserInfo_ModifyInformationSave">保 存</el-button>
              </div>
              <div v-if="tab == 'third'"><!--任职信息-->
                <el-button @click="dialogModifyTeacher = false">取 消</el-button>
                <el-button type="primary" @click="servingInformationSave" v-if="authe.Hotel_Personnel_UserInfo_JobInformationSave">保 存</el-button>
              </div>
              <div v-if="tab == 'fourth'"><!--管理设备-->
                <el-button @click="dialogModifyTeacher = false">取 消</el-button>
                <el-button type="primary" @click="managementEquipmentSave" v-if="authe.Hotel_Personnel_UserInfo_ManagementEquipmentSave">保 存</el-button>
              </div>
              <div v-if="tab == 'eight'">
                <el-button @click="dialogModifyTeacher = false">取 消</el-button>
                <el-button type="primary" @click="eightSave">保 存</el-button>
              </div>
            </div>
          </el-dialog>

          <el-dialog title="添加授权" :visible.sync="dialogAddFace" @close="closeDialogAddFace" v-loading="loading" element-loading-text="拼命加载中" :close-on-click-modal="false" width="900px">
            <el-steps :active="face.active" finish-status="success" simple style="margin-top: 20px">
              <el-step title="添加授权"></el-step>
              <el-step title="选择设备"></el-step>
            </el-steps>
              <div v-if="face.active == 0" style="margin-top: 10px">
                卡号：<el-input v-model="face.studentName" @change="cardNumberPost()" size="mini" style="width:200px"></el-input>&nbsp;&nbsp;
                <el-button type="primary" icon="el-icon-camera-solid" @click="photographUpload" size="mini"></el-button>
                <el-upload
                        action=""
                        :data="picData"
                        ref="upload"
                        list-type="picture-card"
                        :auto-upload="false"
                        :on-change="getFile"
                        :before-upload="beforeUpload"
                        :on-success="handleAvatarSuccess"
                        :on-error="handleError"
                        :on-preview="handlePictureCardPreview"
                        :before-remove="beforeRemove"
                        :on-remove="handleRemove"
                        :file-list="pic"
                        :limit="1"
                        :on-exceed="onExceed"
                        multiple
                        style="margin-top: 10px">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <p class="photo-p">图片大小不能超出400kb</p>
              </div>
              <div v-if="face.active == 1" style="margin-top: 10px">
                设备名称：<el-input v-model="face.devNickname" @change="searchUnauthorized" size="mini" style="width:20%"></el-input>&nbsp;
                设备SN：<el-input v-model="face.devSn" @change="searchUnauthorized" size="mini" style="width:20%"></el-input>
                设备安装地址: <el-input v-model="face.deviceAddress" @change="searchUnauthorized" size="mini" style="width:20%"></el-input>
                <el-table
                        ref="unauthorized"
                        :data="dataFaceUnauthorized"
                        @selection-change="selectionChange"
                        style="width:100%;margin-bottom:10px">
                  <el-table-column type="selection" width="30"></el-table-column>
                  <el-table-column prop="idftName" label="设备类型" width="130px"></el-table-column>
                  <el-table-column prop="idstName" label="设备型号" width="175px"></el-table-column>
                  <el-table-column prop="devNickname" label="设备名称" width="175px"></el-table-column>
                  <el-table-column prop="hsAddCommunity" label="设备安装地址" width="175px"></el-table-column>
                  <el-table-column prop="devSn" label="设备SN" width="175px"></el-table-column>
                </el-table>
              </div>
            <div slot="footer">
              <el-button @click="dialogAddFace = false">取 消</el-button>
              <el-button type="primary" v-show="this.face.active < 1" @click="next">下一步</el-button>
              <el-button v-show="this.face.active > 0" @click="prev">上一步</el-button>
              <el-button type="primary" v-show="this.face.active > 0" @click="addFaceSave">保 存</el-button>
            </div>
          </el-dialog>

          <el-dialog :visible.sync="dialogVisible"><!--点击图片查看大图-->
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>

          <el-dialog :visible.sync="dialogPhotograph" :close-on-click-modal="false" width="700px"><!--相机窗口-->
            <div class="camera_outer">
              <video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay></video>
              <canvas style="display:none;" id="canvasCamera" :width="videoWidth" :height="videoHeight" ></canvas>
              <div v-if="imgSrc" class="img_bg_camera">
                <img :src="imgSrc" alt="" class="tx_img">
              </div>
            </div>
            <div slot="footer" style="text-align: center">
              <el-button type="primary" v-show="photographButton" @click="photograph" :disabled="!verification" icon="el-icon-camera-solid"></el-button>
              <el-button type="primary" v-show="!photographButton" @click="photographTrue" icon="el-icon-check"></el-button>
              <el-button type="primary" v-show="!photographButton" @click="photographFalse" icon="el-icon-close"></el-button>
            </div>
          </el-dialog>
          <el-button type="primary" size="mini" @click="isAuthorization = true">批量授权</el-button>
          <!-- Authorization -->
            <el-dialog title="批量授权" :visible.sync="isAuthorization" width="620px">
              <volume-authorization  @ok="isAuthorization = false" type="用户" v-if="isAuthorization" />
            </el-dialog>
        <el-button type="primary" @click="putTeacherNormalQuit" size="mini" v-if="authe.Hotel_Personnel_UserInfo_GeneralDeparture">普通离职</el-button>   
        <el-button type="primary" @click="teacherQuit" size="mini" v-if="authe.Hotel_Personnel_UserInfo_EmployeeTransfer">员工离职交接</el-button>
        <el-button type="primary" @click="departmentExChange" size="mini" v-if="authe.Hotel_Personnel_UserInfo_DepartmentSwap">部门调换</el-button>
          <el-dialog :title="this.title" :visible.sync="dialogExChange" @close="closeExChangeDialog" :close-on-click-modal="false" width="600px">
            <el-form>
              <el-form-item>
                <p>{{this.pTitle1}}</p>
                <div style="padding-left: 20px">
                  <span>
                    店面：<el-input v-model="exChange.storefrontName" disabled size="mini" style="width:23%"></el-input>&nbsp;&nbsp;
                  </span>
                  <span>
                    部门：<el-input v-model="exChange.departmentName" disabled size="mini" style="width:23%"></el-input>&nbsp;&nbsp;
                  </span>
                  <span>
                    姓名：<el-input v-model="exChange.suStaffName" disabled size="mini" style="width:23%"></el-input>
                  </span>
                </div>
              </el-form-item>
              <el-form-item>
                <p>{{this.pTitle2}}</p>
                <div style="padding-left: 20px">
                  <span>
                    店面：<el-select v-model="exChange.storefrontId" @change="searchDepartment" size="mini" style="width:23%">
                      <el-option v-for="(value,i) in dataListLeft" :key="i" :label="value.storefrontName" :value="value.storefrontId"></el-option>
                    </el-select>&nbsp;&nbsp;
                  </span>
                  <span>
                    部门：<el-select v-model="exChange.departmentId"  @change="searchTeacherName" size="mini" style="width:23%">
                      <el-option v-for="(value,i) in exChange.department" :key="i" :label="value.departmentName" :value="value.departmentId"></el-option>
                    </el-select>&nbsp;&nbsp;
                  </span>
                  <span v-show="showState">
                    姓名：<el-select v-model="exChange.userCoding" size="mini" style="width:23%">
                      <el-option v-for="(value,i) in exChange.teacherName" :key="i" :label="value.suStaffName" :value="value.userCoding"></el-option>
                    </el-select>
                  </span>
                </div>          
              </el-form-item>
            </el-form>
            <div slot="footer">
              <el-button @click="dialogExChange = false">取 消</el-button>
              <el-button type="primary" v-if="showState" @click="teacherQuitSave">保 存</el-button>
              <el-button type="primary" v-else @click="departmentExChangeSave">保 存</el-button>
            </div>
          </el-dialog>
        <el-button type="primary" @click="uploadImg" size="mini">查看及上传附件</el-button>
          <!-- 图片上传组件 -->
          <common-upload ref='upload' @listenEvent='dialogImgPathClose' :dialog="dialogImgPath" :save='needSavePhoto' :photoid='photoTableId' :photorandom='photoRandom' :phototype='photoTableType' ></common-upload>
      </el-row>
      <el-row style="padding: 5px 5px 0 0;">
        姓名：<el-input v-model="formRight.nameSearch" @change="getDataRight" clearable size="mini" style="width:100px"></el-input>&nbsp;&nbsp;
        员工状态：<el-select v-model="formRight.studentState" @change="getDataRight" clearable size="mini" style="width:100px">
          <el-option label="正常" value="正常"></el-option>
          <el-option label="注销" value="注销"></el-option>
          <el-option label="离职" value="离职"></el-option>
        </el-select>&nbsp;
      </el-row>
    </el-col>

  </el-row>
  <el-row style="text-align:center;"><!--数据表-->
    <el-col :span="6">
      <el-table 
      :data="dataListLeft" 
      ref="tableLeft" 
      highlight-current-row 
      @current-change="currentChangeLeft"
      @row-dblclick="dbModifySchool" 
      :height="tableHeight" 
      size="mini" 
      style="width=100%;border-right:1px solid #ebeef5"><!--数据表-->
        <el-table-column prop="storefrontName" label="店面名称" min-width="100px"></el-table-column>
      </el-table>
    </el-col>
    <el-col :span="6">
      <el-table 
      :data="dataListCenter" 
      @current-change="currentChangeCenter"
      @row-dblclick="dbModifyDepartment" 
      ref="tableCenter" 
      highlight-current-row 
      :height="tableHeight" 
      size="mini" 
      style="width=100%;border-left:1px solid #ebeef5;border-right:1px solid #ebeef5"><!--数据表-->
        <el-table-column prop="departmentName" label="部门名称" min-width="100px"></el-table-column>
      </el-table>
    </el-col>
    <el-col :span="12">
      <el-table 
      :data="dataListRight" 
      ref="tableRight" 
      highlight-current-row 
      @current-change="currentChangeRight"
      @row-dblclick="dbmodifyTeacher"
      :height="tableHeight" 
      size="mini" 
      style="width=100%;border-left:1px solid #ebeef5"><!--数据表-->
        <el-table-column prop="suState" label="员工状态" min-width="100px"></el-table-column>
        <el-table-column prop="suStaffName" label="姓名" min-width="100px"></el-table-column>
        <el-table-column prop="suContact" label="手机号码" min-width="100px"></el-table-column>
        <el-table-column prop="suName" label="员工账号" min-width="100px"></el-table-column>
      </el-table>
    </el-col>
  </el-row>
  <el-dialog
    title="部门详细"
    :visible.sync="CeDetaildia"
    width="500px"
    :close-on-click-modal="false"
    >
    <div class="project-detail">
      <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>店面位置：</span>
              {{formCenter.storefrontName}}
            </el-col>
            <el-col :span="12">
              <span>部门状态：</span>
              {{formCenter.departmentState}}
            </el-col>
          </el-row>
        </div>
        <div class="describe">
          <el-row>
            <el-col :span="12">
              <span>部门名称：</span>
              {{formCenter.departmentName}}
            </el-col>
          </el-row>
        </div><div class="describe">
          <el-row>
            <el-col :span="12">
              <span>部门描述：</span>
              {{formCenter.departmentNote}}
            </el-col>
          </el-row>
        </div>
    </div>
    
  </el-dialog>
</div>
</template>
<script>
import $ from '../../assets/jquery'
import { ZK } from "../../assets/baseISSOnline"
import { getBrowserType } from "../../assets/baseISSOnline"
import { issOnlineUrl } from "../../assets/baseISSOnline"
import { browserFlag } from "../../assets/baseISSOnline"
import { setting } from "../../assets/baseISSOnline"
import { setCertificateData } from "../../assets/baseISSOnline"
import { msgType } from "../../assets/baseISSOnline"
import { openMessage } from "../../assets/baseISSOnline"
import { closeMessage } from "../../assets/baseISSOnline"
import { strlen } from "../../assets/baseISSOnline"
import { messageBox } from "../../assets/baseISSOnline"
import { Device } from "../../assets/baseISSOnline"
import { createISSonlineDevic } from '../../assets/baseISSOnline'
import { getUserId2 } from "../../assets/utils";
import { mapState } from "vuex";
import commonUpload from '../../components/common-upload'
import VolumeAuthorization from './components/VolumeAuthorization.vue'
export default {
  name:'teacher',
  data(){
    let suPasswordVer = (rule, value, callback) => {
      if (value == this.teacher.suName) {
        callback(new Error('登录密码不能与账号相同'))
      }
    }
    return{
      rules: {//验证规则
        storefrontName: [
          {required: true,message: '店面名称不能为空',trigger: 'blur',},
          {min: 1,max: 50,message: '店面名称长度为 1 到 50 个字符',trigger: 'blur',}
        ],
        departmentName: [
          {required: true,message: '部门名称不能为空',trigger: 'blur',},
          {min: 1,max: 50,message: '部门名称长度为 1 到 50 个字符',trigger: 'blur',}
        ],
        suStaffName: [
          {required: true,message: '姓名不能为空',trigger: 'blur',},
          {min: 1,max: 50,message: '姓名名称长度为 1 到 50 个字符',trigger: 'blur',}
        ],
        suContact: [
          {required: true,message: '电话不能为空',trigger: 'blur',},
          {
            pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
            message: '电话格式错误',
            trigger: 'blur'
          }
        ],
        suPassword: [
          {required: true,message: '登录密码不能为空',trigger: 'blur',},
          {validator: suPasswordVer,trigger: 'blur' },
        ],
      },
      isAuthorization: false,
      storeState: false,
      storeState1: false,
      departmentState: false,
      labelWidth: '80px',
      tableData: [],
      rowLeft: '',
      rowCenter: '',
      rowRight: '',
      tableHeight: window.innerHeight - 186,
      dialogSchool: false,
      dialogSchool1: false,
      dialogDepartment: false,
      dialogAddTeacher: false,
      dialogModifyTeacher: false,
        dialogAddFace: false,
        dialogImageUrl: '',
        dialogVisible: false,
        dialogPhotograph: false,
      dialogExChange: false,
      title: '',
      pTitle1: '',
      pTitle2: '',
      showState: false,
      CeDetaildia: false,
      formLeft: {//左表按钮
        storefrontAccountId: '1',
        storefrontName: '',
        storefrontAddress: '',
        storefrontState: '正常',
        storefrontNote: '',

        schoolState: '正常',
      },
      formCenter: {//中表按钮
        storefrontId: '',
        departmentName: '',
        departmentState: '正常',
        departmentNote: '',

        depState: '正常',
      },
      formRight: {//右表按钮
        nameSearch: '',
        studentState: '正常',
      },
      exChange: {//选择条件
        department: [],
        teacherName: [],
        storefrontName: '',
        departmentName: '',
        suStaffName: '',
        storefrontId: '',
        departmentId: '',
        userCoding: '',
      },
      tab: 'first',
      teacher: {
        // 授权卡
        storefrontId: '',
        departmentId: '',
        suStaffName: '',//姓名
        suContact: '',//电话
        suStaffNation: '',//民族
        suStaffCurrentaddress: '',//居住地址
        suStaffNativeplace: '',//籍贯
        suIdcard: '',//证件号码
        suStaffAddress: '',//证件地址
        suStaffServicememotextnull: '',//备注信息
        suStaffMarriage: '',//婚姻状况
        suState: '正常',//员工状态
        suName: '',//员工账号
        suPassword: '',//登录密码
        // suMd5Check: '',//安全加密
        // suMd5CheckType: '0',//安全级别
        suAppAuth: "0",//运维app
        suStaffLinkman: '',//紧急联系人
        suStaffLinkmanrelation: '',//联系人关系
        suStaffLinkmanphone: '',//联系人电话
        suStaffRemark: '',//备注
        url: '',
        suDiscountAuthPassword:{},//授权密码
      },
      suDiscountAuthPassword:{
        cardPassword:'',
        authPassword:'',
      },
      psdWarningAlert: false,
      psdWarningMessage: false,
      dataListLeft: [],
      dataListCenter: [],
      dataListRight: [],
      dataTeacher: [],//单个员工信息
      dataFollowUp: [],//用户跟进
      serving: {//任职信息
        storefrontName: '',
        departmentName: '',
        suType: '',
        mainRole: "",
        otherRole: "",
        // bankname: '',
        // banknum: '',
        suPermissionsId: '',
        suSuperior: '',
      },
      roleOpt: [],
      dataPost: [],//岗位列表
      dataJurisdiction: [],//权限列表
      dataName: [],//直属上级列表
      dataBinding: [],//已绑定设备列表
      dataUnbinding: [],//未绑定设备列表
      face: {
        devNickname: '',
        devSn: '',
        active: 0,
        studentName: '',
        deviceAddress: '',
      },
      loading: false,
      dataFaceAuthorized: [],
      dataFaceUnauthorized: [],
        selectionChangeRow: [],//多选数组
        pic: [],
        picData:{             //上传图片携带的参数
          token : "",
        },
      //拍照相关
        videoWidth: 640,
        videoHeight: 480,
        imgSrc: '',
        url: '',
        thisCancas: null,
        thisContext: null,
        thisVideo: null,
        photographButton: true,
        photoPushState: false,
        autoUpload: true,
        verification: true,
      
      //上传图片
        dialogImgPath:false,
        needSavePhoto:false,
        photoTableId:'',
        photoTableType:'',
        photoRandom:'',
        service: {
          jwucKfAccount: '',
          jwucKfNick: '',
          jwucKfPassword: '',
          jwucWxUserAccount: '',
        },
    }
  },
  components: {
    'common-upload':commonUpload,
    'volume-authorization':VolumeAuthorization
  },
  computed: {
    ...mapState(["userNameList", "userName",'authe'])
  },
  created() {
    this.getDataLeft();
    this.getToken()
  },
  methods: {
    demo(){console.log(this.formLeft.storefrontName)}
    ,
  //表单验证
    submitForm(formName) {
      let state = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          state = true
        } else {
          state = false
        }
      })
      return state
    },
    resetForm(formName) {
      if(this.$refs[formName]!==undefined){
        setTimeout(() => {
        // this.$refs[formName].resetFields();//清除name
        this.$refs[formName].clearValidate();//不清除name
        },1)
      }

    },
  //表格数据查询相关
    getDataLeft() {//左表查询
      this.$axios({
        method: 'get',
        url: `/common1/storefronts`,
        params: {
          storefrontState: this.formLeft.schoolState
        }
      }).then(res=>{
        if(res.data.result && res.data.result.length>0){
          this.dataListLeft=res.data.result;
          this.rowLeft = this.dataListLeft[0]
          this.$refs.tableLeft.setCurrentRow(this.dataListLeft[0]);//默认选中左表第一条
        }else{
          this.dataListLeft = []
        }
      }).catch(err => {
        if(this.dataListLeft == [] || this.formLeft.storefrontState == ''){
          this.formLeft.schoolState = '正常';
          this.dataListLeft = [];
        } else {
          this.formLeft.schoolState = '注销';
          this.dataListLeft = [];
        }
        this.isError(err,this)
      })
    },
    getDataCenter(){//中表查询
      console.log(this.formCenter.departmentState)
      if(this.dataListLeft.length > 0){
        this.$axios({
          method: 'get',
          url: `/common1/departments`,
          params: {
            departmentStorefrontId: this.rowLeft.storefrontId,
            departmentState: this.formCenter.depState
          }
        }).then(res=>{
          console.log("成功");
          if(res.data.result && res.data.result.length>0){
            this.dataListCenter=res.data.result;
            this.rowCenter = this.dataListCenter[0]
            this.$refs.tableCenter.setCurrentRow(this.dataListCenter[0]);
          }else{
            this.dataListCenter=[]
          }
        }).catch(err => {
          console.log("失败");
          if(this.dataListCenter == [] || this.formCenter.depState == '注销'){
            this.dataListCenter = [];
          }
          if(this.dataListCenter == [] || this.formCenter.depState == '正常' ||  this.formCenter.departmentState == '正常'){
            // this.formCenter.depState = this.formCenter.departmentState = '注销';
            this.dataListCenter = [];
          }
          console.log(this.formCenter.depState);
          console.log(this.formCenter.departmentState);
          console.log('右表报错');
          this.isError(err,this)
        })
      }else{
        this.dataListCenter = []
      }
    },
    getDataRight(){//右表查询
      if(this.dataListCenter.length > 0){
        this.$axios({
          method: 'get',
          url: `/common1/users`,
          params: {
            suStoreId: this.rowLeft.storefrontId,
            suDepartmentId: this.rowCenter.departmentId,
            suStaffName: this.formRight.nameSearch,
            suState: this.formRight.studentState
          }
        }).then(res=>{
          this.dataListRight=res.data.result.records
        }).catch(err => {
          this.isError(err,this)
        })
      }else{
        this.dataListRight = []
      }
    },

  //查询相关
    currentChangeLeft(row){//左表选中改变时调用
      this.rowLeft = row;
      this.dataListCenter = []
      this.getDataCenter();
    },
    currentChangeCenter(row){//中表选中改变时调用
      this.rowCenter = row;
      this.dataListRight = []
      this.getDataRight();
    },
    currentChangeRight(row){//右表改变时
      this.rowRight = row;
    },

  //添加修改店面相关
    addSchool(){//添加店面弹框赋值
      this.title = '新增店面'
      this.showState1 = false
      // this.storeState = false
      this.dialogSchool1 = true
      this.resetForm('schoolForm')
    },
    addSchoolSave(){//添加店面保存
      let datas={
        storefrontName:this.formLeft.storefrontName,
        schoolState:this.formLeft.schoolState,
        storefrontAccountId:this.formLeft.storefrontAccountId,
        storefrontAddress:this.formLeft.storefrontAddress,
        storefrontNote:this.formLeft.storefrontNote,
        storefrontState:this.formLeft.storefrontState,
      }
      if(this.submitForm('schoolForm')){
        this.loading=true
        this.$axios({
          method: 'post',
          url: '/common1/storefronts',
          data:datas
        }).then(res => {
          this.loading=false
          this.dialogSchool = false
          this.getDataLeft()
          this.$message.success('添加成功')
        }).catch(err => {
          this.loading=false
          this.isError(err,this)
        })
      }
      this.dialogSchool1 = false
    },
    dbModifySchool(){//双击修改
      this.storeState = true
      this.$axios({
        method: 'get',
        url: `/common1/storefronts/${this.rowLeft.storefrontId}`,
      }).then(res => {
        for( let i in this.formLeft){//赋值
          for( let j in res.data.result){
            if(i==j){
              this.formLeft[i] = res.data.result[j]
            }
          }
        }
        this.title = '修改店面'
        this.showState = true
        this.dialogSchool = true
        this.resetForm('schoolForm')
      }).catch(err => {
        this.isError(err,this)
      })
    },
    modifySchool(){//修改店面弹框赋值
      if(!this.rowLeft){
        this.$message.warning('请先选中一个店面')
      }else{
        this.storeState = false
        this.$axios({
          method: 'get',
          url: `/common1/storefronts/${this.rowLeft.storefrontId}`,
        }).then(res => {
          for( let i in this.formLeft){//赋值
            for( let j in res.data.result){
              if(i==j){
                this.formLeft[i] = res.data.result[j]
              }
            }
          }
          this.title = '修改店面'
          this.showState = true
          this.dialogSchool1 = true
          this.resetForm('schoolForm')
        }).catch(err => {
          this.isError(err,this)
        })
      }
    },
    modifySchoolSave(){//修改店面保存
      let datas={
        storefrontName:this.formLeft.storefrontName,
        schoolState:this.formLeft.schoolState,
        storefrontAccountId:this.formLeft.storefrontAccountId,
        storefrontAddress:this.formLeft.storefrontAddress,
        storefrontNote:this.formLeft.storefrontNote,
        storefrontState:this.formLeft.storefrontState,
      }
      if(this.submitForm('schoolForm')){
        this.loading = true;
        this.$axios({
          method: 'put',
          url:`/common1/storefronts/${this.rowLeft.storefrontId}`,
          data:datas
        }).then(res => {
          this.loading=false
          this.dialogSchool = false
          if(this.formLeft.schoolState == '注销' || this.formLeft.storefrontState == '正常'){
            this.formLeft.schoolState = this.formLeft.storefrontState;
          }
          this.getDataLeft()
          this.$message.success('修改成功')
        }).catch(err => {
          this.loading=false
          this.isError(err,this)
        })
      }
      this.dialogSchool1 = false
    },
    closeSchoolDialog(){//关闭时清空弹框
      for( let i in this.formLeft){//清空
        this.formLeft[i] = ''
      }
      this.showState = false
      this.formLeft.storefrontState = '正常'
      this.formLeft.schoolState = '正常'
    },

  //添加修改部门相关
    addDepartment(){//添加部门弹框赋值
      this.title = '新增部门'
      this.showState = false

      this.formCenter.storefrontId = this.rowLeft.storefrontId.toString()
      this.dialogDepartment = true
      this.resetForm('departmentForm')
    },
    addDepartmentSave(){//添加部门保存
      if(this.submitForm('departmentForm')){
        this.loading=true
        this.$axios({
          method: 'post',
          url: '/common1/departments',
          data: this.formCenter
        }).then(res => {
          this.loading=false
          this.dialogDepartment = false
          this.getDataCenter()
          this.$message.success('添加成功')
        }).catch(err => {
          this.loading=false
          this.isError(err,this)
        })
      }
    },
    dbModifyDepartment(){//双击修改
      this.$axios({
        method: 'get',
        url: `/common1/departments/${this.rowCenter.departmentId}`,
      }).then(res => {
        this.formCenter = res.data.result.records[0]
        this.formCenter.depState=this.formCenter.departmentState
        console.log(this.formCenter);
        this.CeDetaildia = true
      }).catch(err => {
        this.isError(err,this)
      })
    },
    modifyDepartment(){//修改部门弹框赋值
      if(!this.rowLeft){
        this.$message.warning('请先选中一个部门')
      }else{
        this.departmentState = false
        this.$axios({
          method: 'get',
          url: `/common1/departments/${this.rowCenter.departmentId}`,
        }).then(res => {
          console.log(res.data.result.records[0]);
          this.formCenter = res.data.result.records[0]
          this.formCenter.depState = this.formCenter.departmentState;
          this.title = '修改部门'
          this.showState = true
          this.dialogDepartment = true
          this.resetForm('departmentForm')
        }).catch(err => {
          this.isError(err,this)
        })
      }
    },
    modifyDepartmentSave(){//修改部门保存
      if(this.submitForm('departmentForm')){
        this.loading=true
        this.$axios({
          method: 'put',
          url:`/common1/departments/${this.rowCenter.departmentId}`,
          data: this.formCenter,
        }).then(res => {
            console.log('中表查询成功');
            this.loading=false
            this.dialogDepartment = false
            this.$message.success('修改成功')
            this.formCenter.depState = this.formCenter.departmentState
            this.getDataCenter();
            this.$store.dispatch('getNameList');
        }).catch(err => {
          this.loading=false
          this.isError(err,this)
        })
      }
    },
    closeDepartmentDialog(){//关闭时清空弹框
      for( let i in this.formCenter){//清空
        if(i === 'departmentState' || i === 'depState'){
          continue
        }
        this.formCenter[i] = ''
      }
      this.showState = false
      // this.formCenter.departmentState = '正常'
      // this.formCenter.depState = '正常'
    },

  //添加员工
    addTeacher(){//添加员工按钮
      this.teacher.storefrontId = this.rowLeft.storefrontId
      if(this.rowCenter==null){
        this.$message({
          message: '请先选择一个部门',
          type: 'warning'
        });
      }else{
        console.log(this.rowCenter)
        this.teacher.departmentId = this.rowCenter.departmentId
        this.dialogAddTeacher = true
      }

      this.resetForm('teacherForm')
    },
    addTeacherSave(){//添加员工提交
      // console.log(this.teacher.suDiscountAuthPassword=JSON.stringify(this.suDiscountAuthPassword))
      this.teacher.suDiscountAuthPassword=JSON.stringify(this.suDiscountAuthPassword)
      if(this.submitForm('teacherForm')){
        this.loading = true
        this.psdWarningAlert = false
        this.$axios({
          method: 'post',
          url: '/common1/users',
          data: this.teacher
        }).then(res => {
          this.loading = false
          this.dialogAddTeacher = false
          this.getDataRight()
          this.$message.success('添加成功')
        }).catch(err => {
          this.loading = false
          if (err.response.data.msg.substr(0,4) == '密码规则') {
            this.psdWarningAlert = true
            this.psdWarningMessage = err.response.data.msg
          } else {
            this.isError(err,this)
          }
        })
      }
    },
    cleanDialogTeacher(){//保存或修改后清空
      this.resetForm('teacherForm')
      this.psdWarningAlert = false
      this.psdWarningMessage = ''
      this.tab = 'first'
      this.teacher = {
        storefrontId: '',
        departmentId: '',
        suStaffName: '',//姓名
        suContact: '',//电话
        suStaffNation: '',//民族
        suStaffCurrentaddress: '',//居住地址
        suStaffNativeplace: '',//籍贯
        suIdcard: '',//证件号码
        suStaffAddress: '',//证件地址
        suStaffServicememotextnull: '',//备注信息
        suStaffMarriage: '',//婚姻状况
        suState: '正常',//员工状态
        suName: '',//员工账号
        suPassword: '',//登录密码
        // suMd5Check: '',//安全加密
        // suMd5CheckType: '0',//安全级别
        suAppAuth: "0",//运维app
        suStaffLinkman: '',//紧急联系人
        suStaffLinkmanrelation: '',//联系人关系
        suStaffLinkmanphone: '',//联系人电话
        suStaffRemark: '',//备注
        url: '',
        suDiscountAuthPassword:{},
      }
      this.serving = {
        //任职信息
        storefrontName: "",
        departmentName: "",
        suType: "",
        mainRole: "",
        otherRole: "",
        // bankname: '',
        // banknum: '',
        suPermissionsId: "",
        suSuperior: ""
      }
      this.service = {
        jwucKfAccount: '',
        jwucKfNick: '',
        jwucKfPassword: '',
        jwucKfWx: '',
      }
    },
    suContactInput() {
      this.teacher.suName = this.teacher.suContact
    },
  //修改员工
    //修改员工信息
      dbmodifyTeacher(row){//双击修改员工
        this.rowRight = row;
        this.service.jwucKfAccount = this.rowRight.userCoding
        this.tabChange()
        this.dialogModifyTeacher = true
      },
      tabChange(tab){//教室详细信息标签改变时
        if(tab){
          if(tab.name == 'first'){
            this.modifyTeacher()//单个员工信息
          }else if(tab.name == 'second'){
            this.getDataFollowUp()//用户跟进
          }else if(tab.name == 'third'){
            this.getTeacher()//查询单个员工店面部门
            this.getDataPost()//查询所有岗位
            this.getDataJurisdiction()//查询所有权限
            this.getDataName()//查询直属上级列表
            this.getRoleList() //查询角色列表
            this.getRoleRel() //查询角色关系
          }else if(tab.name == 'fourth'){
            this.getDataBinding()
          }else if(tab.name == 'fifth'){
            this.getDataFaceAuthorized()
          }else if(tab.name == 'eight'){
            this.getPersonalDetails()
          }
        }else{
          this.modifyTeacher()
        }
      },
      modifyTeacher(){//修改员工赋值
        this.$axios({
          method: 'get',
          url: `/common1/users/${this.rowRight.userCoding}`
        }).then(res => {
          console.log(res)
          this.dataTeacher = res.data.result.records[0]
          console.log(this.dataTeacher)
          for (let i in this.dataTeacher) {
            for (let j in this.teacher) {
              if (i == j) {
                // console.log(this.dataTeacher[i]);
                this.teacher[j] = this.dataTeacher[i]
                // console.log(this.teacher[j])
              }
            }
            if(this.dataTeacher.suStaffIdImgPers) {
              this.teacher.url = `data:image/png;base64,${this.dataTeacher.suStaffIdImgPers}`
            }
          }
          console.log(this.teacher)
          this.teacher.suDiscountAuthPassword=JSON.parse(res.data.result.suDiscountAuthPassword)
          console.log(this.teacher.suDiscountAuthPassword)
          this.resetForm('teacherForm')
        }).catch(err => {
          // this.isError(err,this)
          console.log(err)
        })
      },
      modifyTeacherSave(){//修改员工保存
        if(this.submitForm('teacherForm')){
          this.teacher.suDiscountAuthPassword=JSON.stringify(this.teacher.suDiscountAuthPassword)
          this.loading = true
          this.psdWarningAlert = false
          this.$axios({
            method: 'put',
            url: `/common1/users/${this.rowRight.userCoding}`,
            data: this.teacher
          }).then(res => {
            this.loading = false
            this.dialogModifyTeacher = false
            this.getDataRight()
            this.$message.success('修改成功')
          }).catch(err => {
            this.loading = false
            if (err.response.data.msg.substr(0,4) == '密码规则') {
              this.psdWarningAlert = true
              this.psdWarningMessage = err.response.data.msg
            } else {
              this.isError(err,this)
            }
          })
        }
      },
    //用户跟进
      getDataFollowUp(){//查询用户跟进
        this.$axios({
          method: 'get',
          url: `/common1/users/${this.rowRight.userCoding}`
        }).then(res => {
          console.log(res);
          if(res.data.result.suFollow != undefined) {
            this.dataFollowUp = JSON.parse(res.data.result.suFollow)
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
    //任职信息
      getTeacher(){//查询单个员工店面部门
        this.$axios({
          method: 'get',
          url: `/common1/users/${this.rowRight.userCoding}`
        }).then(res => {
          this.dataTeacher = res.data.result
          for (let i in this.dataTeacher) {
            for (let j in this.serving) {
              if (i == j) {
                this.serving[j] = this.dataTeacher[i]
              }
            }
          }
          if(this.dataTeacher.suPermissionsId != undefined) {
            this.serving.suPermissionsId = Number(this.dataTeacher.suPermissionsId)
          }else{
            this.serving.suPermissionsId = "";
          }
          console.log(this.userName);
          console.log(this.dataTeacher.suSuperior);
          // this.serving.suSuperior = [`${this.dataTeacher.storefrontId}`,`${this.dataTeacher.departmentId}`,`${this.dataTeacher.suSuperior}`]
          // this.serving.suSuperior = getUserId2(this.userName,this.dataTeacher.suSuperior)
          this.serving.suSuperior = getUserId2(this.userName,this.dataTeacher.suSuperior)
          console.log(this.serving.suSuperior)
        }).catch(err => {
          this.isError(err,this)
        })
      },
      getDataPost(){//查询所有岗位
        this.$axios({
          method: 'get',
          url: `/common1/companyVar/1`
        }).then(res => {
          this.dataPost = JSON.parse(res.data.result.userType)
        }).catch(err => {
          this.isError(err,this)
        })
      },
      getDataJurisdiction(){//查询所有权限
        this.$axios({
          method: 'get',
          url: `/common1/authorities`
        }).then(res => {
          this.dataJurisdiction = res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      getDataName(){//查询直属上级列表
        this.$axios({
          method: 'get',
          url: `/common1/shiftSchedules/users`
        }).then(res => {
          //取出一级菜单
            for(let i = 0;i<res.data.result.records.length;i++) {
              this.dataName[i] = {};
              this.dataName[i].value = res.data.result.records[i].storefrontId;
              this.dataName[i].label = res.data.result.records[i].storefrontName;
              this.dataName[i].children = [];
            }
          //一级菜单去重
            let schoolArr = [];
            let schoolObj = {};
            for ( let i = 0;i < this.dataName.length ; i++) {
              if (!schoolObj[this.dataName[i].label]) {
                schoolArr.push(this.dataName[i]);
                schoolObj[this.dataName[i].label] = true;
              }
            }
            this.dataName = schoolArr;
          //取出二级菜单
            for (let i = 0; i < this.dataName.length ; i++) {
              for ( let j = 0 ; j < res.data.result.records.length ; j++) {
                if ( this.dataName[i].value === res.data.result.records[j].storefrontId){ 
                  let school = {};
                  school.value = res.data.result.records[j].departmentId;
                  school.label = res.data.result.records[j].departmentName;
                  school.children = [];
                  this.dataName[i].children.push(school);
                }
              }
            }
          //二级菜单去重
            for ( let i = 0;i < this.dataName.length ; i++) {
            let departmentArr = [];
            let departmentObj = {};
              for ( let j = 0;j < this.dataName[i].children.length ; j++) {
                if (!departmentObj[this.dataName[i].children[j].label]) {
                  departmentArr.push(this.dataName[i].children[j]);
                  departmentObj[this.dataName[i].children[j].label] = true;
                }
              }
              this.dataName[i].children = departmentArr;
            }
          //取出三级菜单
            for ( let i = 0;i < this.dataName.length ; i++) {
              for ( let j = 0;j < this.dataName[i].children.length ; j++) {
                for ( let k = 0;k < res.data.result.records.length ; k++) {
                  if ( this.dataName[i].children[j].value === res.data.result.records[k].departmentId){
                    let department = {};
                    department.value = res.data.result.records[k].userCoding;
                    department.label = res.data.result.records[k].suStaffName;
                    this.dataName[i].children[j].children.push(department);
                  }
                }
              }
            }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      servingInformationSave(){//修改任职信息保存
        console.log(this.serving.suSuperior)
        for(let i in this.serving.suSuperior){
          this.serving.suSuperior=parseInt(this.serving.suSuperior[i])
        }
        // this.serving.suSuperior = Number(this.serving.suSuperior)
        this.$axios({
          method: 'put',
          url: `/common1/users/${this.rowRight.userCoding}`,
          data: this.serving
        }).then(res => {
          this.dialogModifyTeacher = false
          this.getDataRight()
          // this.$message.success('修改成功')
        }).catch(err => {
          this.isError(err,this)
        })
        let arr = []
        let obj = {
          surMasterSlave: 0,
          surRoleId: this.serving.mainRole,
          surUid: Number(this.rowRight.userCoding)
        }
        arr.push(obj)
        for (let i = 0; i < this.serving.otherRole.length; i++) {
          let obj2 = {
            surMasterSlave: 1,
            surRoleId: this.serving.otherRole[i],
            surUid: Number(this.rowRight.userCoding)
          }
          arr.push(obj2)
        }
        console.log(arr);
        this.$axios({
          url: '/common1/userRoles',
          method: 'post',
          data: arr
        }).then(res => {
          this.dialogModifyTeacher = false;
          this.getDataRight();
          this.$message.success("修改成功");
        }).catch(err => {
          this.isError(err,this)
        })
      },
      getRoleList(){//获取角色下拉框
        this.$axios({
          url: '/common1/roles',
          params: {
            cursor: 1,
            limit: 1000,
            srRoleName: ""
          }
        }).then(res => {
          this.roleOpt = res.data.result.records
        }).catch(err => {
          this.isError(err,this)
        })
      },
      getRoleRel(){//获取角色关系
        this.$axios({
          url: '/common1/userRoles',
          method: 'get',
          params: {
            surUid: this.rowRight.userCoding
          }
        }).then(res => {
          let data = res.data.result
          for (const item of data) {
            if(item.surMasterSlave == 0){
              this.serving.mainRole = item.surRoleId
            }else{
              this.serving.otherRole.push(item.surRoleId)
            }
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
    //管理设备
      getDataBinding(){//获取已授权设备
        this.dataBinding = []//已绑定设备列表
        this.dataUnbinding = []//未绑定设备列表
        this.$axios({
          method: 'get',
          url: '/common1/userDevice',
          params: {
            judUserId: this.rowRight.userCoding,
          }
        }).then(res => {
          let result = res.data.result
          for (let i = 0 ; i < result.length ; i++) {
            let obj = {
              id: result[i].jourDeviceId,
              name: `${result[i].hsAddCommunity ? result[i].hsAddCommunity : ''}\u3000\u3000${result[i].devNickname}`
            }
            this.dataBinding.push(obj)
          }
          console.log(this.dataBinding)
        }).catch(err => {
          this.isError(err,this)
        })
        this.$axios({
          method: 'get',
          url: '/common1/userDevice',
          params: {
            binding: '绑定',
            judUserId: this.rowRight.userCoding,
          }
        }).then(res => {
          let result = res.data.result
          let arr = []
          for (let i of result) {
            arr.push(i.jourDeviceId)
          }
          this.dataUnbinding = arr
        }).catch(err => {
          this.isError(err,this)
        })
      },
      managementEquipmentSave(){//设备授权保存
        let userDevicePARM = {
          devices: this.dataUnbinding,
          users: [this.rowRight.userCoding],
        }
        this.$axios({
          method: 'post',
          url: '/common1/userDevice/userDevices',
          data: userDevicePARM
        }).then(res => {
          this.$message.success('授权成功')
        }).catch(err => {
          this.isError(err,this)
        })
      },
      cleanGrant(){//关闭后清空
        this.dataBinding = []//已绑定设备列表
        this.dataUnbinding = []//未绑定设备列表
      },
    //人脸授权
      getDataFaceAuthorized(){//获取已授权人脸设备
        this.$axios({
          method: 'get',
          url: `/common2/devices/faces`,
          params: {
            state: '绑定',
            userId: this.rowRight.userCoding,
            personType: '员工',
            devNickname: this.face.devNickname,
            devSn: this.face.devSn,
            address: this.face.deviceAddress
          }
        }).then(res => {
          this.dataFaceAuthorized = res.data.result
          for(let i of this.dataFaceAuthorized){
            let a = i.hsAddCommunity ? `${i.hsAddCommunity}\u3000` : ''
            let b = i.hsAddBuilding ? `${i.hsAddBuilding}\u3000` : ''
            let c = i.hsAddDoorplateno ? `${i.hsAddDoorplateno}` : ''
            i.hsAddCommunity = a + b + c
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      getDataFaceUnauthorized(){//获取未授权人脸设备
        this.$axios({
          method: 'get',
          url: `/common2/devices/faces`,
          params: {
            state: '不绑定',
            userId: this.rowRight.userCoding,
            personType: '员工',
            devNickname: this.face.devNickname,
            devSn: this.face.devSn,
            address: this.face.deviceAddress
          }
        }).then(res => {
          this.dataFaceUnauthorized = res.data.result
          for(let i of this.dataFaceUnauthorized){
            let a = i.hsAddCommunity ? `${i.hsAddCommunity}\u3000` : ''
            let b = i.hsAddBuilding ? `${i.hsAddBuilding}\u3000` : ''
            let c = i.hsAddDoorplateno ? `${i.hsAddDoorplateno}` : ''
            i.hsAddCommunity = a + b + c
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      searchAuthorized(){//名称、SN改变时查询
        this.getDataFaceAuthorized()
      },
      deleteAuthorized(row){//删除单个授权
        this.loading = true
        this.$axios({
          method: 'delete',
          url: `/common2/facePersons/${this.rowRight.userCoding}/marketing`,
          params: {
            deviceIds: row.jourDeviceId,
            personnelType: '员工',
          }
        }).then(res => {
          this.$message.success('删除成功')
          this.getDataFaceAuthorized()
          this.loading = false
        }).catch(err => {
          this.loading = false
          this.isError(err,this)
        })
      },
      addFace(){//添加授权按钮
        this.dialogAddFace = true
        this.getFace()
        this.getDataFaceUnauthorized()
      },
    faceIdentifyDelete(row){//人脸识别删除授权
      this.loading = true
      this.$axios({
        method: 'delete',
        url: `/common2/facePersons/${this.rowRight.userCoding}/marketing`,
        params: {
          deviceIds: row.jourDeviceId,
          personnelType: '员工',
        }
      }).then(res => {
        this.$message.success('删除成功')
        this.getDataFaceAuthorized()
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    faceSnapDelete(row){//人脸抓拍删除授权
      this.loading = true
      this.$axios({
        method: 'delete',
        url: `/common2/facePersons/${row.ifpId}/deviceFaceDelete`,
        params: {
          deviceId: row.jourDeviceId
        }
      }).then(res => {
        this.$message.success('删除成功')
        this.getDataFaceAuthorized()
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.isError(err,this)
      })
    },
    deleteAuthorized(row){//删除单个授权
      row.devFirstType == 24 && this.faceIdentifyDelete(row)
      row.devFirstType == 33 && this.faceSnapDelete(row)
    },
      searchUnauthorized(){//名称、SN改变时查询
        this.getDataFaceUnauthorized()
      },
      selectionChange(row){//多选
        this.selectionChangeRow = row
      },
      faceObj(deviceIds){
        let obj = {
          deviceIds: deviceIds,
          personnelId: this.rowRight.userCoding,
          personnelType: '员工'
        }
        return obj
      },
      addFaceSave(){//添加员工授权保存
        if (this.selectionChangeRow.length < 1) {
          this.$message.warning('请至少选中一个设备')
        }else{
          this.loading = true
          //判断设备类型
          let faceIdentifyArr = []
          let faceSnapyArr = []
          this.selectionChangeRow.forEach(item => {
            item.devFirstType == 24 && faceIdentifyArr.push(item.jourDeviceId)
            item.devFirstType == 33 && faceSnapyArr.push(item.jourDeviceId)
          })
          if(faceIdentifyArr.length != 0){
            this.$axios({
              method: 'post',
              url: '/common2/facePersons',
              data: this.faceObj(faceIdentifyArr)
            }).then(res => {
              this.$message.success('添加成功')
              this.dialogAddFace = false
              this.getDataFaceAuthorized()
              this.loading = false
            }).catch(err => {
              this.loading = false
              this.isError(err,this)
            })
          }
          if(faceSnapyArr.length != 0){
            this.$axios({
              method: 'post',
              url: '/common2/facePersons/faceRegistration',
              data: this.faceObj(faceSnapyArr)
            }).then(res => {
              this.$message.success('添加成功')
              this.dialogAddFace = false
              this.getDataFaceAuthorized()
              this.loading = false
            }).catch(err => {
              this.loading = false
              this.isError(err,this)
            })
          }
        }
      },
      next(){//下一步
        if (this.pic.length < 1) {
          this.$message.warning('请授权至少一张照片')
        }else{
          this.face.active++ > 1 ? this.face.active = 1 : this.face.active
        }
        // this.face.active++ > 1 ? this.face.active = 1 : this.face.active
      },
      prev(){//上一步
        // setTimeout(() => {
        //   this.selectionChangeRow.forEach(row => {
        //     this.$refs.unauthorized.toggleRowSelection(row)
        //   })
        // },1)
        this.face.active-- < 0 ? this.face.active = 0 : this.face.active
      },
      closeDialogAddFace(){//添加授权关闭时清空
        this.face = {
          devNickname: '',
          devSn: '',
          active: 0,
          studentName: '',
        }
        this.dataFaceUnauthorized = []
        this.selectionChangeRow = []//多选数组
        this.pic = []
      },
      //上传图片
        getToken(){
          this.$axios({
            method: 'get',
            url: '/common1/photos/token',
            params: {
              bucket: '公有'
            }
          }).then(res => {
            //获取 token
            this.picData.token = res.data.result;
          }).catch(err => {
            this.isError(err,this)
          })
        },
        onExceed(files, fileList){//超出文件限制的钩子
          if(fileList.length >= 3){
            this.$message.warning('最多只能上传3张照片')
          }
        },
        beforeUpload(){//上传完文件前获取token
          // this.getToken()
        },
        handleAvatarSuccess(res,file,fileList){//上传成功后在图片框显示图片
          // this.dialogImageUrl =[`https://pic-gongkai.fangzhizun.com/${res.key}?imageView2/0/w/480`]
          // // file.url = this.dialogImageUrl
          // this.pic = fileList
          // console.log(file)
          // console.log(this.pic)
          // this.cardNumberPost()
        },
        handleError(res){//显示错误
          console.log(res)
        },
        handlePictureCardPreview(file){//查看某张图片的原图
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
        },
        beforeRemove(file){//删除前
          return this.$confirm(`确定移除？`);
        },
        handleRemove(file,fileList){//删除后
          this.pic = fileList
          this.dialogImageUrl = ''
          this.$axios({//删除人脸照片
            method: 'delete',
            url: `/common2/facePersons/${this.rowRight.userCoding}/pictureDelete`,
            params: {
              personnelType: '员工',
            }
          }).then(res => {
            // this.getFace()
            // console.log(this.pic)
          }).catch(err => {
            this.isError(err,this)
          })
        },
      //拍照相关
        getCompetence(){//调用权限
          console.log('1')
          let _this = this
          _this.verification = true
          setTimeout(() =>{
            _this.thisCancas = document.getElementById('canvasCamera')
            _this.thisContext = _this.thisCancas.getContext('2d')
            _this.thisVideo = document.getElementById('videoCamera')
            // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
            if (navigator.mediaDevices === undefined) {
              navigator.mediaDevices = {}
            }
            // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
            // 使用getUserMedia，因为它会覆盖现有的属性。
            // 这里，如果缺少getUserMedia属性，就添加它。
            if (navigator.mediaDevices.getUserMedia === undefined) {
              navigator.mediaDevices.getUserMedia = constraints => {
                // 首先获取现存的getUserMedia(如果存在)
                let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia
                // 有些浏览器不支持，会返回错误信息
                // 保持接口一致
                if (!getUserMedia) {
                  return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
                }
                // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
                return new Promise((resolve, reject) => {
                  getUserMedia.call(navigator, constraints, resolve, reject)
                })
              }
            }
            let constraints = { 
              audio: false, 
              video: { 
                width: _this.videoWidth,
                height: _this.videoHeight, 
                transform: 'scaleX(-1)'
              } 
            }
            navigator.mediaDevices.getUserMedia(constraints).then(stream => {
              // 旧的浏览器可能没有srcObject
              if ('srcObject' in _this.thisVideo) {
                _this.thisVideo.srcObject = stream
              } else {
                // 避免在新的浏览器中使用它，因为它正在被弃用。
                _this.thisVideo.src = window.URL.createObjectURL(stream)
              }
              _this.thisVideo.onloadedmetadata = e => {
                _this.thisVideo.play()
              }
            }).catch(err => {
              console.log(err)
              _this.verification = false
              console.log(_this.verification)
              _this.$message({
                showClose: true,
                duration: 6000,
                message: `未检测到摄像头`,
                type: 'warning'
              })
            })
          },1)
        },
        setImage(){//绘制图片
          // let _this = this
          // 点击，canvas画图
          this.thisContext.drawImage(this.thisVideo, 0, 0, this.videoWidth, this.videoHeight)
          // 获取图片base64链接
          let image = this.thisCancas.toDataURL('image/jpeg',.8)
          this.imgSrc = image
          // this.$emit('refreshDataList', this.imgSrc)
        },
        dataURLtoBlob(base64Data) {//base64转blob
          //console.log(base64Data);//data:image/png;base64,
          let byteString;
          if(base64Data.split(',')[0].indexOf('base64') >= 0)
              byteString = atob(base64Data.split(',')[1]);//base64 解码
          else{
              byteString = unescape(base64Data.split(',')[1]);
          }
          let mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];//mime类型 -- image/png

          // let arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
          // let ia = new Uint8Array(arrayBuffer);//创建视图
          let ia = new Uint8Array(byteString.length);//创建视图
          for(let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
          }
          let blob = new Blob([ia], {
              type: mimeString
          });
          return blob;
        },
        stopNavigator(){//关闭摄像头
          this.thisVideo.srcObject.getTracks()[0].stop()
        },
        photographUpload(){//拍照上传按钮
          this.autoUpload = false
          this.dialogPhotograph = true
          this.getCompetence()
        },
        photograph(){//拍照按钮
          this.setImage()
          this.getToken()
          this.photographButton = false
        },
        photographTrue(){//拍照确定
          this.photoPushState = true
          this.pic.length == 0 ? this.cardNumberPost(this.imgSrc) : this.$message.warning('图片已存在')
          // this.$axios({
          //   method: 'get',
          //   url: '/common1/qiniu/token'
          // }).then(res => {
          //   //获取 token
          //   this.picData.token = res.data
          //   if (this.imgSrc!==''){
          //     let file = this.dataURLtoBlob(this.imgSrc) // 把整个base64给file
          //     // let type = "image/jpeg"; // 定义图片类型（canvas转的图片一般都是png，也可以指定其他类型）
          //     let time=(new Date()).valueOf()//生成时间戳
          //     let name = `${time}.png`; // 定义文件名字（例如：abc.png ， cover.png）
          //     let key = name
          //     let token = this.picData.token
          //     let putExtra = {
          //       fname: "",
          //       params: {},
          //       mimeType: null
          //     }
          //     let config = {
          //       useCdnDomain: true,
          //     }
          //     let observable = this.$qiniu.upload(file, key, token, putExtra, config)
          //     let subscription = observable.subscribe(this.process, this.error, this.complete)
          //     this.stopNavigator()
          //     this.imgSrc = ''
          //     this.url = ''
          //     this.photographButton = true
          //     this.autoUpload = true
          //     this.dialogPhotograph = false
          //   }
          // }).catch(err => {
          //   this.isError(err,this)
          // })
        },
        process(res){//七牛云上传进度
          console.log(res)
        },
        error(err){//七牛云上传报错
          console.log(err)
        }, 
        complete(res){//七牛云上传成功
          console.log(res)
          this.url = [`https://pic-gongkai.fangzhizun.com/${res.key}?imageView2/0/w/480`]
          let obj = {name: name, url: this.url}
          this.pic.push(obj)
          console.log(this.url)
          console.log(this.pic)
          this.photoRequest()
        },
        photographFalse(){//拍照取消
          this.imgSrc = ''
          this.photographButton = true
        },
      photoRequest(){
        this.$axios({//点击拍照确认按钮请求
          method: 'post',
          url:`/common2/facePersons/pictureUpload`,
          data: {
            personnelType: '员工',
            personnelId : this.rowRight.userCoding,
            url: JSON.stringify(this.url)
          },
        }).then(res => {
          // this.getFace()
        }).catch(err => {
          // this.getFace()
          this.isError(err,this)
        })
      },
    
  //员工离职相关
    putTeacherNormalQuit() {
      if(!this.rowRight){
        this.$message.warning('请先选中一名员工')
      }else{
        console.log(this.rowRight);
        
        this.$confirm(`确定为 ${this.rowRight.storefrontName} -
        ${this.rowRight.departmentName} -
        ${this.rowRight.suStaffName} 办理离职吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.$axios({//离职请求
            method: 'put',
            url:`/common1/users/${this.rowRight.userCoding}`,
            data: {
              suState: '离职'
            }
          }).then(res => {
            this.getDataRight()
            this.$message.success('离职成功')
          }).catch(err => {
            this.isError(err,this)
          })

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消离职'
          });
        });
      }
    },

  //员工离职交接或调换查询相关
    getDataDepartmentExChange(){//根据员工查询店面部门
      this.$axios({
        method: 'get',
        url: `/common1/users/${this.rowRight.userCoding}`
      }).then(res => {
        // for( let i in this.exChange){//赋值
        //   for( let j in res.data.result.records[0]){
        //     if(i==j){
        //       this.exChange[i] =res.data.result.records[0][j]
        //     }
        //   }
        // }
        // this.exChange.storefrontId = ''
        // this.exChange.departmentId = ''
        // this.exChange.userCoding = ''
        // this.dialogExChange = true
        console.log(res)
        this.exChange.storefrontName=res.data.result.storefrontName
        this.exChange.departmentName=res.data.result.departmentName
        this.exChange.suStaffName=res.data.result.suStaffName
        this.dialogExChange = true
      }).catch(err => {
        // this.isError(err,this)
        console.log(err)
      })
    },
    searchDepartment(){//下拉店面改变时查部门
      if(this.dataListLeft.length > 0){
        this.$axios({
          method: 'get',
          url: `/common1/departments`,
          params: {
            departmentStorefrontId: this.exChange.storefrontId
          }
        }).then(res=>{
          if(res.data.result && res.data.result.length>0){
            this.exChange.department=res.data.result;
          }else{
            this.exChange.department=[]
          }
        }).catch(err => {
          this.isError(err,this)
        })
      }else{
        this.exChange.department = []
      }
    },
    searchTeacherName(){//下拉部门改变时查员工
      if(this.exChange.department.length > 0){
        this.$axios({
          method: 'get',
          url: `/common1/users`,
          params: {
            suStoreId: this.exChange.storefrontId,
            suDepartmentId: this.exChange.departmentId
          }
        }).then(res=>{
          this.exChange.teacherName=res.data.result.records;
          console.log(this.exChange.teacherName);
        }).catch(err => {
          this.isError(err,this)
        })
      }else{
        this.exChange.teacherName = []
      }
    },
    closeExChangeDialog(){//关闭时清空弹框
      for( let i in this.exChange){//清空
        this.exChange[i] = ''
      }
      this.showState = false
    },

  //员工离职交接相关
    putTeacherQuit(){//员工离职交接请求
      this.$axios({
        method: 'put',
        url:`/common1/users/${this.rowRight.userCoding}/handover`,
        params: {
          userId: this.exChange.userCoding
        }
      }).then(res => {
        if( this.exChange.userCoding==this.rowRight.userCoding){
          this.$message.warning('离职用户与接手用户不能为同一人')
        }else{
          this.dialogExChange = false
          this.getDataRight()
          this.$message.success('交接成功')
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    teacherQuit(){//员工离职交接弹框赋值
      this.title = '离职操作'
      this.pTitle1 = '离职员工'
      this.pTitle2 = '接手员工'
      this.showState = true
      if(!this.rowRight){
        this.$message.warning('请先选中一名员工')
      }else{
        this.getDataDepartmentExChange()
      }
    },
    teacherQuitSave(){//员工离职交接保存
      this.putTeacherQuit()
    },

  //部门调换相关
    putdepartmentExChange(){//部门调换请求
      this.$axios({
        method: 'put',
        url:`/common1/users/${this.rowRight.userCoding}/changeDep`,
        // data: this.exChange,
        params:{
          newDepId:this.exChange.departmentId,
          newStoId:this.exChange.storefrontId
        }
      }).then(res => {
        if(this.exChange.departmentId==this.rowCenter.departmentId){
          this.$message.warning('调转部门不能是原部门')
        }else{
          this.dialogExChange = false
          this.getDataCenter()
          this.$message.success('调转成功')
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    departmentExChange(){//部门调换弹框赋值
      this.title = '调转部门'
      this.pTitle1 = '原先部门'
      this.pTitle2 = '调转部门'
      this.showState = false
      if(!this.rowRight){
        this.$message.warning('请先选中一名员工')
      }else{
        this.getDataDepartmentExChange()
      }
    },
    departmentExChangeSave(){//部门调换保存
      this.putdepartmentExChange()
    },
  
  //读取身份证
    startFun(){ //读取身份证按钮
      new Device().startFun()  //读取身份证触发函数
      console.log(window.massageList) //读取身份证返回值
      let teacherMsg=window.massageList.Certificate
      this.teacher.suStaffName=teacherMsg.Name
      this.teacher.suStaffAddress=teacherMsg.Address
      this.teacher.suStaffNation=teacherMsg.Nation
      this.teacher.suIdcard=teacherMsg.IDNumber
      this.teacher.url="data:image/jpg;base64," + teacherMsg.Base64Photo
    },
    cardNumberPost(jDialogImageUrl){//卡号和照片
      this.$axios({
        method: 'post',
        url:`/common2/facePersons/pictureUpload`,
        data: {
          personnelType: '员工',
          personnelId : this.rowRight.userCoding,
          ifpCardno: this.face.studentName,
          base64: jDialogImageUrl
        },
      }).then(res => {
        this.dialogImageUrl = ''
        this.dialogPhotograph = false
        if(this.photoPushState){
          let obj = {url: jDialogImageUrl}
          this.pic.push(obj)
        }
      }).catch(err => {
        this.pic = []
        this.isError(err,this)
      })
    },
    getFace(){
      this.pic = []
      this.$axios({
        method: 'get',
        url:`/common2/facePersons/photo`,
        params: {
          personnelType: '员工',
          personnelId : this.rowRight.userCoding,
        },
      }).then(res => {
        let ifpGuidPhoto = JSON.parse(res.data.result.ifpGuidPhoto)
        for(let i = 0; i < ifpGuidPhoto.length; i++){
          let faceObj = {
            id: ifpGuidPhoto[i].faceGuid,
            url: ifpGuidPhoto[i].faceUrl
          }
          this.pic.push(faceObj)
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getFile(file, fileList){//图片上传本地
      this.photoPushState = false
      this.pic = fileList
      this.getBase64(file.raw).then(res => {
        this.cardNumberPost(res)
      });
    },
	  getBase64(file) {//转base64
        // return new Promise(function(resolve, reject) {
          let reader = new FileReader();
          let imgResult = "";
          reader.readAsDataURL(file);
          let img = new Image();
          reader.onload = function (e) {
            img.src = e.target.result;
          }
          let imgP = new Promise ((resolve, reject) => {
            img.onload = () => {
              let canvas = document.createElement('canvas');
              let ctx = canvas.getContext('2d');
              // 瓦片 canvas
              let tCanvas = document.createElement('canvas');
              let tctx = tCanvas.getContext('2d');
              let initSize = img.src.lengtth;
              let width = img.width;
              let height = img.height;
              // 图片像素大于400万像素，计算压缩到400万以下
              let ratio;
              if ((ratio = width * height / 4000000) > 1) {
                ratio = Math.sqrt(ratio);
                width /= ratio;
                height /= ratio;
              } else {
                ratio = 1;
              }
              canvas.width = width;
              canvas.height = height;
              ctx.fillStyle = '#FFF';
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              // 如果图片太大则使用瓦片绘制
              let count;
              if ((count = width * height / 1000000 > 1)) {
                count = ~~(Math.sqrt(count) + 1); // 计算分成的瓦片数
                let nw = ~~(width / count);
                let nh = ~~(height / count);
                tCanvas.width = nw;
                tCanvas.height = nh;
                for (let i = 0; i < count; i ++) {
                  for (let j = 0; j < count; j ++) {
                    tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh);
                    ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
                  }
                }
              } else {
                ctx.drawImage(img, 0, 0, width, height);
              }
              // 进行最小压缩
              let ndata = canvas.toDataURL('image/jpeg', 0.3);
              tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
              resolve(ndata);
            }
          })
          return Promise.all([imgP])
      },
  //上传图片
    uploadImg(){
      if(!this.rowRight){
        this.$message.warning('请先选中一名用户')
      }else{
        this.photoRandom = parseInt((Math.random() * 9 + 1) * 100000000000);
        this.needSavePhoto=true//需要保存按钮
        this.photoTableId=this.rowRight.userCoding
        this.photoTableType='用户'
        this.dialogImgPath=true
        this.$nextTick(()=>{
          this.$refs.upload.photoReflash()
        })
      }
    },
    dialogImgPathClose(){//上传图片弹窗关闭 
      this.dialogImgPath=false
    },
    eightSave() {
      this.$axios({
        method: 'post',
        url:`/common3/wxUserCustomer/register`,
        params: {
          jwucKfAccount: this.service.jwucKfAccount,
          jwucKfNick: this.service.jwucKfNick,
          jwucKfPassword: this.service.jwucKfPassword,
          jwucWxUserAccount: this.service.jwucKfWx,
          jwucUserId: this.rowRight.userCoding
        },
      }).then(res => {
        this.$message.success('保存成功')
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getPersonalDetails() { // 获取个人客服信息
        this.$axios({
            method: 'get',
            url: '/common3/wxUserCustomer/userCustomer',
            params: {
              jwucUserId: this.rowRight.userCoding
            }
        }).then(res => {
          if (res.data.result.length > 0) {
            this.service = res.data.result[0]
          }
        }).catch(err => {
            this.isError(err, this);
        })
    },
  }
}

</script>
<style lang="scss" scoped>
  .camera_outer{
    width: 640px;
    height: 480px;
    margin: auto;
    position: relative;
    overflow: hidden;
    background-size: 100%;
    video,canvas,.tx_img{
      -moz-transform:scaleX(-1);
      -webkit-transform:scaleX(-1);
      -o-transform:scaleX(-1);
      transform:scaleX(-1);
    }
    .img_bg_camera{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .describe /deep/.el-col {
  font-size: 14px;
}
  .photo-p{
    font-size: 12px;
    color: red;
    margin-top: 0;
  }
</style>
