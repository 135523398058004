<template>
  <div class="auth">
    <el-row>
      <el-col :span="10">
        <el-table
          ref="stuTable"
          height="425"
          :data="studentData"
          tooltip-effect="dark"
          style="width: 155"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="ifpName" label="姓名" width="100"> </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="10">
        <el-table
          ref="equipTable"
          :data="equipData"
          height="425"
          tooltip-effect="dark"
          style="width: 225"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="devNickname" label="设备" width="160"> </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="2" style="position:relative;height:425px">
        <el-button 
        type="primary"
        :disabled="isdisabled"
        @click="save" 
        style="position:absolute;bottom:0">确认授权</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { findregist, findequip,Volumeauth } from '../../../api/student'
export default {
  data() {
    return { 
      // 防抖
      isdisabled:false,
      equipData: [],
      studentData: [],
      multipleSelection: [],
    };
  },
  props: {
    // 传入类型 学生 / 教职工
    type: {
      type: String
    }
  },
  created() {
    // 初始化请求表格数据
    this.getStudent()
    this.getequip()
  },
  methods: {
    handleSelectionChange() {},
    // 获取学生数据
    getStudent() {
      findregist(this.type).then(res => {
        console.log(res.data.result)
        this.studentData = res.data.result
      }).catch(err => {
        this.isError(err,this)
      })
    },
    // 获取设备数据
    getequip() {
      findequip().then(res => {
        this.equipData = res.data.result
      }).catch(err => {
        this.isError(err,this)
      })
    },
    // 批量授权
    save() {
      // equipTable
      const equip = this.$refs.equipTable.store.states.selection
      const stu = this.$refs.stuTable.store.states.selection
      if(!stu.length) {
        return this.$message.error(`未选择需要授权的${this.type}`);
      }
      if(!equip.length) {
        return this.$message.error('未选择需要授权的设备');
      }
      this.isdisabled = true
      // ifpPersonId
      const deviceIds = equip.map(item => {
        return item.jourDeviceId
      })
      const ifpIds = stu.map(item => {
        return item.ifpId
      })
      const data = {
        deviceIds,
        ifpIds,
        personnelType: this.type
      }
      Volumeauth(data).then(res => {
        this.$message({
          message: '授权成功',
          type: 'success'
        });
        // 触发父组件方法  关闭dialog
        // this.$emit('ok')
        this.isdisabled = false
        // 清空选择框
        this.$refs.equipTable.clearSelection()
        this.$refs.stuTable.clearSelection()
      }).catch(err => {
        this.$message.error('授权失败')
        // 清空选择框
        this.$refs.stuTable.clearSelection()
        this.$refs.equipTable.clearSelection()
        this.isdisabled = false
      })
    }
  },
};
</script>

<style></style>
