<template>
  <div>
    <el-dialog width="680px" title="查看图片" v-loading='loading' @close='dialogImgPathClose' :visible.sync="dialogImgPath" :close-on-click-modal="false" append-to-body>
      <div style="width:100%;height:400px;overflow-y:auto">
        <el-row>
          <el-button size='mini' @click="photoUpByPhone" type="primary">手机上传</el-button>
          <el-button size='mini' @click="photoReflash" type="primary">刷新</el-button>
            <el-dialog width="280px" title="微信扫一扫上传" @close='dialogPhoneClose' :visible.sync="dialogPhone" :close-on-click-modal="false" append-to-body>
              <el-row type="flex" class="row-bg" justify="space-around">
                <el-col :span="24">
                  <canvas id="QRCode"></canvas>
                </el-col>
              </el-row>
            </el-dialog>
          <!-- <span>{{'图片'+imgNum+'张'+' '+'文件'+fileNum+'个'}}</span> -->
        </el-row>
        <el-upload
          class="upload-demo"
          action="https://upload-z2.qiniup.com"
          :data="picData"
          :file-list="photoList"
          list-type="picture-card"
          :before-remove='beforeRemove'
          :before-upload="beforeUpload"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :on-preview="seeBigPhotos"
          multiple
        >
        <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogBigImg" append-to-body>
          <img width="100%" :src="bigImgUrl" />
        </el-dialog>
      </div>
      <div slot="footer" v-show='needSavePhoto'>
        <el-button @click="dialogImgPath=false">取消</el-button>
        <el-button @click='savePhotos' type="primary">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcode"; //引入生成二维码插件
  export default {
    //父传子，大小写不敏感故不是驼峰命名,依次绑定为弹窗,保存按钮,上传图片外表id , 随机数, 上传图片类型
    props:['dialog','save','photoid','photorandom','phototype','bucket'],
    data(){
      return{
        loading:false,
        dialogBigImg:false,//查看大图弹框
        dialogImgPath:false,//查看图片弹框
        dialogPhone:false,//手机上传弹框
        delIdStr:'',//删除图片id集合
        delInfoStr:'',//删除图片名称集合
        bigImgUrl:'',//大图url路径
        fileNum:0,//文件数量
        imgNum:0,//图片数量
        photoList:[],//所有图片显示数组
        photoList2:[],//查原有图片显示数组
        phoneUrl:'',//手机上传二维码
        picData: {//上传图片前的格式
          token: "",
          "x:co": sessionStorage.getItem('co'),
          "x:photoRandom": '', //十二位随机数
          "x:photoTableType": '', //审批，任务
          "x:photoMimeType": "", //文件，图片
          "x:photoBucket": "" //公用，私有
        },
        photoBucket:'私有',
        photoTableId:'',//外表id保存的时候传
        needSavePhoto:false,//false代表不需要保存图片
      }
    },
    watch: {
      // 通过监听获取数据
      phoneUrl(val) {
        // 获取页面的canvas
        let msg = document.getElementById("QRCode");
        console.log(val,msg)
        // 将获取到的数据（val）画到msg（canvas）上
        QRCode.toCanvas(msg, val, function(error) {
          console.log(error);
        });
      },
      dialog(val){
        console.log(val)
        this.dialogImgPath=val
      },
      save(val){
        console.log(val)
        this.needSavePhoto=val
      },
      photoid(val){
        console.log(val)
        this.photoTableId=val
      },
      photorandom(val){
        console.log(val)
        this.picData["x:photoRandom"]=val
      },
      phototype(val){
        console.log(val)
        this.picData["x:photoTableType"]=val
      },
      bucket(val){
        this.photoBucket=val?'公有':'私有'
        console.log(val,1,this.photoBucket)
      }
    },
    methods:{
      getToken() {//获取token
        return this.$axios({
          url: "/common1/photos/token/callback",
          method: "get",
          params: {
            bucket: this.photoBucket
          }
        }).then(res => {
          return res.data.result;
        }).catch(err => {
          this.isError(err,this)
        })
      },
      getDownloadUrl(url){//下载凭证获取图片路径
        return this.$axios({//下载凭证获取图片路径
          method:'get',
          url:'/common1/photos/getDownloadUrl',
          params:{
            baseUrls:url
          }
        }).then(res=>{
          return res.data.result[0].original
        }).catch(err => {
          this.isError(err,this)
        })
      },
      async beforeUpload(file) {//上传前
        console.log(file)
        try {
          let getToken = await this.getToken();//上传前先获取token
          this.picData.token = getToken;
          if(file.type == "image/jpeg" || file.type == "image/jpg" || file.type === "image/gif" || file.type == "image/png" || file.type == "image/bmp"){
            this.picData["x:photoMimeType"] = "图片"
          }
          if(file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/gif" && file.type != "image/png" && file.type != "image/bmp"){
            this.picData["x:photoMimeType"] = "文件"
          }
          // this.picData["x:photoRandom"]=this.photoRandom
          // this.picData["x:photoTableType"]=this.photoTableType
          this.picData["x:filename"] = file.name;
          this.picData["x:co"] = sessionStorage.getItem('co');
          this.picData["x:photoBucket"] = this.photoBucket;
          // this.picData["x:photoTableId"] = this.homeRow.popId;
        } catch (err) {
          console.log(err);
        }
      },
      handleAvatarSuccess(res, file, fileList) {//上传成功后
        this.photoList = fileList;
      },
      seeBigPhotos(file) {//查看大图
        this.bigImgUrl = file.url;
        this.dialogBigImg = true;
      },
      beforeRemove(file, fileList) {//删除前
        return this.$confirm(`确定移除 ${ file.name }？`);
      },
      handleRemove(file, fileList) {//删除后（走接口真删除）
        if(file.raw){
          if(file.raw.type == "image/jpeg" || file.raw.type == "image/jpg" || file.raw.type === "image/gif" || file.raw.type == "image/png" || file.raw.type == "image/bmp"){
            this.imgNum=this.imgNum-1
          }
          if(file.raw.type != "image/jpeg" && file.raw.type != "image/jpg" && file.raw.type != "image/gif" && file.raw.type != "image/png" && file.raw.type != "image/bmp"){
            this.fileNum=this.fileNum-1
          }
        }
        if(file.photoMimeType=='图片'){
          this.imgNum=this.imgNum-1
        }else if(file.photoMimeType=='文件'){
          this.fileNum=this.fileNum-1
        }
        this.$axios({
          method:'delete',
          url:'/common1/photos',
          params:{
            photoIds:file.response.result
          }
        }).then(res=>{
          this.$message.success('删除成功')
        }).catch(err=>{
          this.isError(err,this)
        })
      },
      photoUpByPhone(){//手机上传
        this.dialogPhone=true
        this.$axios({
          method:'get',
          url:'/common1/photos/getMobUploadUrl',
          params:{
            photoBucket:this.photoBucket,
            photoMimeType:'图片',
            photoRandom:this.picData["x:photoRandom"],
            photoTableType:this.picData["x:photoTableType"],
          }
        }).then(res=>{
          this.phoneUrl=res.data.result
        }).catch(err=>{
          this.isError(err,this)
        })
      },
      async photoReflash(){//查询已有照片和临时照片&&刷新按钮
        this.imgNum=0
        this.fileNum=0
        this.photoList=[]
        // this.loading=true
        await this.$axios({//通过随机数查临时图片
          method:'get',
          url:'/common1/photos',
          params:{
            photoRandom:this.picData["x:photoRandom"],
            photoTableType:this.picData["x:photoTableType"]
          }
        }).then(async res=>{
          this.loading=false
          let imgdata = res.data.result;
          if(res.data.result.length==0){
            return
          }
          for (let i = 0; i < imgdata.length; i++) {//将图片拼接为element识别格式
            if(imgdata[i].photoMimeType=='图片'){
              this.imgNum=this.imgNum+1
            }else if(imgdata[i].photoMimeType=='文件'){
              this.fileNum=this.fileNum+1
            }
            let obj = {
              photoStatus:imgdata[i].photoStatus,
              photoMimeType:imgdata[i].photoMimeType,
              name: imgdata[i].photoName,
              url:await this.getDownloadUrl(imgdata[i].photoUrl),
              response: {
                result: imgdata[i].photoId
              }
            };
            this.photoList.push(obj);
          }
        }).catch(err=>{
          this.loading=false
          this.isError(err,this)
        })
        if(this.needSavePhoto){//true表示要查已有的图片
          this.getPhotoByTableId()
        }
      },
      async getPhotoByTableId(){//通过外表id查已有图片
        await this.$axios({
          method:'get',
          url:'/common1/photos',
          params:{
            photoTableId:this.photoTableId,
            photoTableType:this.picData["x:photoTableType"]
          }
        }).then(async res=>{
          if(res.data.result.length==0){
            return
          }
          let imgdata = res.data.result;
          for (let i = 0; i < imgdata.length; i++) {//将图片拼接为element识别格式
            if(imgdata[i].photoMimeType=='图片'){
              this.imgNum=this.imgNum+1
            }else if(imgdata[i].photoMimeType=='文件'){
              this.fileNum=this.fileNum+1
            }
            let obj = {
              photoStatus:imgdata[i].photoStatus,
              photoMimeType:imgdata[i].photoMimeType,
              name: imgdata[i].photoName,
              url:await this.getDownloadUrl(imgdata[i].photoUrl),
              response: {
                result: imgdata[i].photoId
              }
            };
            this.photoList.push(obj);
          }
          console.log(this.photoList,2)
        }).catch(err => {
          this.isError(err,this)
        })
      },
      dialogPhoneClose(){//手机上传弹窗关闭
        this.photoReflash()
      },
      savePhotos(){//保存按钮，所有新上传的图片有保存按钮不点保存都不会真上传，无保存按钮的传随机数给后台保存
        this.loading=true
        this.$axios({
          method:'put',
          url:'/common1/photos',
          params:{
            photoRandom:this.picData["x:photoRandom"],
            photoTableId : this.photoTableId,
            photoTableType:this.picData["x:photoTableType"]
          }
        }).then(res=>{
          this.loading=false
          this.dialogImgPath=false
          this.$message.success('保存成功')
        }).catch(err=>{
          this.loading=false
          this.isError(err,this)
        })
      },
      dialogImgPathClose(){
        if(this.needSavePhoto){
          this.photoList=[]
        }
        this.$emit('listenEvent',false)
      },
    }
  }
</script>

<style lang="scss">
.el-upload-list__item {
      transition: none !important;
    }
</style>