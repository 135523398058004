import request from '../utils/request'

/**
 * 查询已注册人员
 * @param {人员类型(教职工学生)} data
 */
export const findregist = (data) => {
  return request({
    url: '/common2/facePersons/list',
    method: 'GET',
    params: {
      personnelType: data
    }
  })
}
export const findequip = (data = "22,30,31,32" ) =>{
  return request({
    url: '/common2/devices/listin',
    method: 'get',
    params: {
      brandId: data
    }
  })
}

export const Volumeauth = (data) => {
  return request({
    url: '/common2/facePersons/batchAuthorize',
    method: 'POST',
    data
  })
}

